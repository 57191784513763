import * as React from 'react';
import { ContainerMaxWidth } from '@hypersay/ui';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import SEO from '../components/seo';
import T from '../components/T';
import { Link } from '../components/Link';
import Layout from '../layouts/default';

// tslint:disable-next-line: no-submodule-imports
import 'react-accessible-accordion/dist/fancy-example.css';

import styles from './privacy.scss';

const L: React.FC<{ url: string; name?: string | React.ReactNode }> = ({
  url,
  name,
}) => (
  <a href={url} rel="noopener noreferrer" target="_blank">
    {name || url}
  </a>
);

const TR: React.FC<
  React.PropsWithChildren<{ message?: string; tagName?: string }>
> = ({ children, message, tagName }) => {
  let result: React.ReactNode;
  if (message) {
    result = <T id={message} />;
  } else if (typeof children === 'string') {
    result = <T id={children} />;
  } else {
    result = children ? children : null;
  }

  if (tagName === 'p') {
    return <p>{result}</p>;
  }

  return <>{result}</>;
};

const personalData: React.ReactNode[][] = [
  [
    <TR>Your primary e-mail address</TR>,
    <TR message="We must know your e-mail address so that we can distinguish you from other users of the website and allow you to sign in and establish a bi-directional communications with you. This information is private and it won’t be shared or made public." />,
  ],
  [
    <TR>Secondary email addresses</TR>,
    <TR message=" Sometimes you use multiple email addresses with your social media accounts. If you have a different email address on LinkedIn or Facebook and you connect with that account, we store it so that we know it is you. If you later sign in with any of the e-mail addresses. This information is private and it won’t be shared or made public. " />,
  ],
  [
    <TR message="Your name and nickname" />,
    <TR message="When you upload a document, you host a live session or participate in one, you become a part of a small community. This community is able to see your name and your nickname. You can change your nickname per session. Although we won’t advertise this information, others, including in some cases the general public may see your name. You can change your name at any time (although we don’t recommend using anything else besides your real name)" />,
  ],
  [
    <TR message="Profile picture" />,
    <TR message="Our platform is aimed to make communication more effective and humans like to see each other’s faces. We show your profile picture in live sessions participants list and also to show who answered a question. This data has the same policy and nature as your name. You can change it at any time." />,
  ],
  [
    <TR message="Social media unique identifiers" />,
    <TR message="We need this information to allow your login with different social networks (such as Google, Facebook or Linkedin). These identifiers are stored privately and securely in our database and will not be transmitted, shared or made public at any time." />,
  ],
  [
    <TR message="Browser token" />,
    <TR message="This is a short password-like string of characters that we use to identify and sign you into our platform and then remember who you are when you open your browser again. This information is securely stored in your browser and deleted when you log out." />,
  ],
  [
    <TR message="Your unique user ID" />,
    <TR message="This is an identifier we securely store in our database and it makes possible that you can change your email address while keeping all of your data. It is not used for processing and it will not be transmitted, shared or made public at any time." />,
  ],
  [
    <TR message="Login IP" />,
    <TR message="We store this address to detect fraudulent login attempts. We also process this address to create anonymous statistics about the countries and cities where Hypersay is used to help us with understanding how to localise it better." />,
  ],
  [
    <TR message="Login history" />,
    <TR message="We store your logins history (just the date) so that we can offer you an audit trail in case you suspect somebody is using your account without your permission" />,
  ],
  [
    <TR message="Your Answers" />,
    <TR message="We store your answers to the interactive elements (such as quizzes, open ended questions or taps on images). We share them with you and the presenter. The presenter can show them to the audience, but your answers are not publicly available, nor indexable. We might publish summarized public statistics that are not identifiable and completely anonymous (e.g. the number of answers given for a specific question, or for a specific  session or for the entire Hypersay platform)" />,
  ],
  [
    <TR message="Answer histories" />,
    <TR message="We store your answer histories (for example how your answer changes for a quiz) so that we can provide an audit trail and undo functionality. We don’t share the the data publicly or with the presenter, but we might chose to process it for totals and statistics (e.g. for a presenter we might have the option to show “on average your participants changed their mind X times for this quiz”)" />,
  ],
  [
    <TR message="Documents you upload" />,
    <React.Fragment>
      <TR
        tagName="p"
        message="We securely store a copy of the files you upload so that you can revisit them . We don’t make the file public and we do not and will not process the file outside the scope of Hypersay’s purposes.  You would not be able to find your uploaded document by using a Google search.  The only way to access documents you upload is by knowing the session code."
      />
      <TR
        tagName="p"
        message="When you upload or give us a link to an existing document, we process the contents of the document we and extract and store the relevant data so that we can offer you the Hypersay experience. We make this data available for you and your participants and for the people that know the unique code and access the page. "
      />
      <TR
        tagName="p"
        message="You can automatically delete this information for documents that haven’t been used in live sessions with more than three people. You can ask us to delete any of your documents (including those that you used to present to more than 3 people), but we kindly ask you to consider your audience’s interests before deleting them."
      />
      <TR
        tagName="p"
        message="If your account is deleted as per your request or because of data expiration policy (we delete personal data after three years of account inactivity), we anonymise your presentations and keep them for archiving purposes, as read-only documents. You may ask us to delete your documents instead of anonymising them when you request your account erasure."
      />
      <TR
        tagName="p"
        message="We may delete documents or part of them as part of legal requirements or issues such as copyright infringement or if they don’t comply with our content policies (e.g. no pornography and no hate speech)."
      />
    </React.Fragment>,
  ],
  [
    <TR message="Customisations you make on the documents" />,
    <TR message="Hypersay allows you to add and remove content on your uploaded documents (or those you start from scratch). These customisations are not part of the original document, but they still belong to you so all the policies described above for the documents also apply for customisations." />,
  ],
  [
    <TR message="Details on live sessions you host" />,
    <TR message="When you host a session, some of your personal data (for example name, profile picture, relevant document and customisation contents, but not the email address) is shared with the participants. Although we don’t publish the session code, anybody that knows this code can join your presentation, anonymously or as a signed in user." />,
  ],
  [
    <TR message="Details on live sessions you participate in" />,
    <TR message="When you join a live session, some of your personal data (for example name, profile picture, but not email address) is shared with the presenter and with the session participants. If the presenter allows anonymous logins, you can join a live session anonymously, by logging out first and then joining the session. " />,
  ],
  [
    <TR message="Questions you ask and you upvote" />,
    <TR message="Questions you ask are shared for the presenter and the other session participants with your name. You can delete your questions. We also store (for the purpose of computing the question score and disallow anybody to vote multiple times) your vote for any of the questions. We don’t publish or share with the presenter or anybody which questions you voted. We only allow registered participants to ask questions to the presenter. " />,
  ],
  [
    <TR message="Claps you receive and give" />,
    <TR message="As a token of appreciation for the presenter, you can applaud the presenter. All of the claps are stored in our database as individual events and are summarized per page and per presentation. We don’t publish or share with the presenter or anybody who clapped, just the anonymous summaries." />,
  ],
  [
    <TR message="Feedbacks you receive and give" />,
    <TR message="At the end of a live session you can send feedback in the form of a star rating and a text comment. The presenter receives only an aggregated score and the text of any feedback.  We do not send, publish or otherwise process your name, image or anything that can be used to identify you. Feedback is anonymous and we only share it with the presenter if more than 3 participants have provided feedback. " />,
  ],
  [
    <TR message="Notes you take" />,
    <TR message="The notes you take are linked to pages in documents. They are your own and not shared with anybody and you can delete any note you wish." />,
  ],
  [
    <TR message="Pages you visit" />,
    <TR message="We need this information to analyse which content we need to improve and how to technically optimise Hypersay (for example it will tell us which pages we need to preload so that you have a faster experience). This data is not published or shared with anybody." />,
  ],
  [
    <TR message="Device Information" />,
    <TR message="When you visit Hypersay some information is automatically collected from your device (e.g., mobile, computer, laptop, tablet) such as operating system, access times, browser information (e.g. type, language). This data is not published or shared with anybody and it is used in its aggregated form by us to understand usage patterns and which improvements we need to address next." />,
  ],
];

const thirdParty: React.ReactNode[][] = [
  [
    <div>
      <strong>Amazon Web Services Inc.</strong>
      <p className={styles.location}>
        Headquarterd in United States, Servers and data in Ireland
      </p>
    </div>,
    <div>
      <p>
        <TR tagName="strong">Infrastructure as a Service</TR>
      </p>
      <p>
        <TR>
          AWS provides the servers where we run the programs that process the
          information and store your files.
        </TR>
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://aws.amazon.com/compliance/eu-data-protection/"
        >
          https://aws.amazon.com/compliance/eu-data-protection/
        </a>
      </p>
    </div>,
  ],
  [
    <div>
      <strong>Google, Inc.</strong>
      <p className={styles.location}>United States</p>
    </div>,
    <div>
      <p>
        <TR tagName="strong">Email and website analytics</TR>
      </p>
      <p>
        <TR message="Google operates our company email systems. We use them when you contact us via email, and Google receives your e-mail address and message contents.Google also provides website analytics services (but they are not linked to any identifiable individual using Hypersay" />
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cloud.google.com/security/gdpr/"
        >
          https://cloud.google.com/security/gdpr/
        </a>
      </p>
    </div>,
  ],
  [
    <div>
      <strong>MongoDB, Inc.</strong>
      <p className={styles.location}>
        United States, All data stored in Ireland{' '}
      </p>
    </div>,
    <div>
      <p>
        <TR tagName="strong">Database as a Service</TR>
      </p>
      <p>
        <TR>
          We use the database to store Hypersay records on users, documents,
          etc.
        </TR>
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mongodb.com/cloud/compliance/gdpr"
        >
          https://www.mongodb.com/cloud/compliance/gdpr
        </a>
      </p>
    </div>,
  ],
  [
    <div>
      <strong>SendGrid, Inc.</strong>
      <p className={styles.location}>United States</p>
    </div>,
    <div>
      <p>
        <TR tagName="strong">Email as a Service</TR>
      </p>
      <p>
        <TR message="SendGrid sends e-mail messages on our behalf (e.g. sign-in codes, messages with reports, notifications, session notes). To send the message they need your e-mail address and the contents of the e-mail. For us to ensure the message deliverability and success, we might also check if links were clicked." />
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://sendgrid.com/resource/general-data-protection-regulation/"
        >
          https://sendgrid.com/resource/general-data-protection-regulation/
        </a>
      </p>
    </div>,
  ],
];

const sections: Array<{ title: any; body: React.ReactNode }> = [
  {
    title: <TR message="Introduction" />,
    body: (
      <React.Fragment>
        <p>
          <TR message="Hypersay is excited about" />{' '}
          <L
            url="https://ec.europa.eu/info/law/law-topic/data-protection_en"
            name="GDPR"
          />{' '}
          <TR message="compliance. Hypersay was originally constructed with a culture of strong data privacy and security principles that are at the heart of GDPR. Being an Education Technology company, our principles of data privacy and security are vital to our growth." />
        </p>

        <TR
          tagName="p"
          message="That being said, we still want to detail and justify every piece of data we collect from our users.  The table below goes through each piece of data we collect and the reasons we do so."
        />

        <TR
          tagName="p"
          message="Hypersay collects a variety of information that you provide directly to us. We process your information when necessary to provide you with the Service that you have requested when accepting our Terms of Service, or where we have obtained your prior consent, or where we have a legitimate interest to do so. For example, we may have a legitimate interest to process your information for security, testing, maintenance, and enhancement purposes of the Services we provide to you, or for analytics, research, and reporting purposes. Without your information, we cannot provide you with the Services you have requested or you may be limited in your use of the Services."
        />

        <TR
          tagName="p"
          message="We do not collect any sensitive personal information such as health-related information, racial or ethnic origin, political opinions, religious beliefs, trade union activities, or sexual life."
        />
      </React.Fragment>
    ),
  },
  {
    title: <TR message="Data Controller" />,
    body: (
      <React.Fragment>
        <TR message="Hypersay is created and operated by Learn Forward Ltd., registered in the United Kingdom, company number" />{' '}
        (
        <L
          url="https://beta.companieshouse.gov.uk/company/09401061"
          name="09401061"
        />
        ).{' '}
        <TR message="Our address is 71 Queen Victoria Street, London, England, EC4V 4BE and if you want learn more about our data privacy or you have general questions you can contact us via the website contact form." />
        <TR
          tagName="p"
          message="Learn Forward is the “data controller” of all personal information that is collected on behalf of and used by Hypersay customers."
        />
      </React.Fragment>
    ),
  },
  {
    title: <TR message="What personal data we collect, why and for how long" />,
    body: (
      <React.Fragment>
        <table>
          <thead>
            <tr>
              <th className="leftColumn">
                <TR message="Personal Data" />
              </th>
              <th>
                <TR message="Why we need it" />
              </th>
            </tr>
          </thead>
          <tbody>
            {personalData.map(([entry, content], index) => (
              <tr key={`personal-${index}`}>
                <td>{entry}</td>
                <td>{content}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <TR
          tagName="p"
          message="Only children aged 16 or over can provide their own consent. For children under this age, consent of the children’s’ parents or legal guardians is required."
        />

        <TR
          tagName="p"
          message="We will not retain your data for longer than is necessary to fulfil the purpose it is being processed for. To determine the appropriate retention period, we consider the amount, nature and sensitivity of the personal data, the purposes for which we process it and whether we can achieve those purposes through other means."
        />

        <TR
          tagName="p"
          message="We must also consider periods for which we might need to retain personal data in order to meet our legal obligations or to deal with complaints, queries and to protect our legal rights in the event of a claim being made."
        />

        <TR
          tagName="p"
          message="When we no longer need your personal data, we will securely delete or destroy it. We will also consider if and how we can minimise over time the personal data that we use, and if we can anonymise your personal data so that it can no longer be associated with you or identify you, in which case we may use that information without further notice to you."
        />
      </React.Fragment>
    ),
  },
  {
    title: <TR message="Third party data processors" />,
    body: (
      <React.Fragment>
        <TR
          tagName="p"
          message="We use a carefully selected list of service providers. Please find the list below, together with information on their location, a link to their data protection compliance page and our comments on how you're affected. We might change this list as new opportunities show up, and we’ll let you know about it."
        />
        <table className="thirdParty">
          <thead>
            <tr>
              <td className="leftColumn">
                <TR>Service Provider</TR>
              </td>
              <td>
                <TR>Why and which data we share</TR>
              </td>
            </tr>
          </thead>
          <tbody>
            {thirdParty.map(([title, content], index) => (
              <tr key={`third-${index}`}>
                <td>{title}</td>
                <td>{content}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    ),
  },
  {
    title: <TR message="Security of your personal data" />,
    body: (
      <React.Fragment>
        <TR
          tagName="p"
          message="We follow strict security procedures in the storage and disclosure of your personal data, and to protect it against accidental loss, destruction or damage. The data you provide to us is protected using SSL (Secure Socket Layer) technology. SSL is the industry standard method of encrypting personal information and credit card details so that they can be securely transferred over the Internet."
        />
        <TR
          tagName="p"
          message="Hypersay takes technical and organizational measures to protect your personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access. However, no method of transmission over the Internet, and no means of electronic or physical storage, is absolutely secure, and thus we cannot ensure or warrant the security of that information."
        />
      </React.Fragment>
    ),
  },
  {
    title: <TR message="How we use your information" />,
    body: (
      <React.Fragment>
        <TR
          tagName="p"
          message="We use your information (including any information that we collect, as described in this Privacy Policy) for various purposes depending on the types of information we have collected from and about you and the specific Hypersay Services you use, including to:"
        />
        <ul>
          <TR
            tagName="li"
            message="Complete a purchase or provide the services you have requested."
          />
          <TR
            tagName="li"
            message="Respond to your request for information and provide you with more effective and efficient customer service."
          />
          <TR
            tagName="li"
            message="Provide you with product updates and information about products you have purchased from us."
          />
          <TR
            tagName="li"
            message="Provide you with service notifications via email and within the Services based on your notification selections."
          />
          <TR
            tagName="li"
            message="Contact you by email, or phone regarding Hypersay and third-party products, services, surveys, research studies, promote special events and other subjects that we think may be of interest to you."
          />
          <TR tagName="li" message="Customize the content you see." />
          <TR
            tagName="li"
            message="Help us better understand your interests and needs, and improve the Services."
          />
          <TR
            tagName="li"
            message="Synthesize and derive insights from your use of Hypersay."
          />
          <TR
            tagName="li"
            message="Engage in analysis, research, and reports regarding use of our Services."
          />
          <TR
            tagName="li"
            message="Provide, manage, and improve the Services."
          />
          <TR tagName="li" message="Protect our Services and our users." />
          <TR
            tagName="li"
            message="Understand and resolve app crashes and other issues being reported."
          />
          <TR
            tagName="li"
            message="We may automatically or manually process the content of the presentations and the user-generated content to ensure compliance with our content policies, for marketing and/or investigative reasons."
          />
        </ul>
        <p>
          <TR tagName="strong" message="Combined Information." />{' '}
          <TR message="You consent that, for the purposes discussed in this Policy, we may combine the information that we collect through the Services with information that we receive from other sources, both online and offline, and use such combined information in accordance with this Policy." />
        </p>

        <p>
          <TR tagName="strong" message="Aggregate/De-Identified Data." />{' '}
          <TR message="We may aggregate and/or de-identify information collected through the Services so that such information can no longer be linked to you or your device (“Aggregate/De-Identified Information”). We may use Aggregate/De-Identified Information for any purpose, including without limitation for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, sponsors, event promoters, and/or others. By using the Services, you consent to such use." />
        </p>
      </React.Fragment>
    ),
  },
  {
    title: <TR message="Cookies and site tracking" />,
    body: (
      <React.Fragment>
        <TR
          tagName="p"
          message="To collect the information in the “Information We Automatically Collect” section above, we and our service providers use Internet server logs, cookies, tags, SDKs, tracking pixels, and other similar tracking technologies. A web server log is a file where website activity is stored. An SDK is a section of code that we embed in our applications and software to allow third parties to collect information about how users interact with the Services. A cookie is a small text file that is placed on your computer or mobile device when you visit a site, that enables us to: (i) recognize your computer and login session; (ii) store your preferences and settings; (iii) understand which web pages of the Services you have visited; (iv), enhance your user experience by delivering and measuring the effectiveness of content and advertising tailored to your interests; (v) perform analytics; and (vi) assist with security and administrative functions. Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny electronic tags with a unique identifier embedded in websites, online ads and/or email, and that are designed to provide usage information like ad impressions or clicks, email open rates, measure popularity of the Services and associated advertising, and to access user cookies. As we adopt additional technologies, we may also gather information through other methods."
        />

        <TR
          tagName="p"
          message="Please note that you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Please consult the “Help” section of your browser for more information (e.g., Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari)."
        />
      </React.Fragment>
    ),
  },
  {
    title: <TR message="Data Protection Officer" />,
    body: (
      <TR
        tagName="p"
        message="We have appointed a Data Protection Officer (“DPO”) to oversee compliance with this policy. Contact him using the form in our Data Protection page or via the web chat option of this page."
      />
    ),
  },
  {
    title: <TR message="Your Data Protection Rights" />,
    body: (
      <>
        <p>
          <TR message="Using our" />{' '}
          <a href={`${process.env.HYPERSAY_FRONTEND_APP_API}/data-protection/`}>
            <TR>GDPR Page</TR>
          </a>
          , <TR message="you can" />:
        </p>
        <ul>
          <TR
            tagName="li"
            message="ask to view your personal data we have on you"
          />
          <TR
            tagName="li"
            message="ask rectification or erasure of your personal data"
          />
          <TR
            tagName="li"
            message="ask for a copy of your data in machine-readable form"
          />
          <TR
            tagName="li"
            message="revoke your consent for us to process your data"
          />
        </ul>

        <TR
          tagName="p"
          message="We don’t use any automated decision-making, including profiling. As the your data you provide is essential for you to use Hypersay, and we don’t process it for any other purpose your lack of consent means you cannot use our services. If you revoke your consent after creating your account, it means you cannot use our services and the services are suspended until you change your mind or you ask for erasure."
        />

        <TR
          tagName="p"
          message="We store your data for the period of the service up to seven years after your last interaction with Hypersay. We may keep some of your information for a longer period for historical, statistical or scientific purposes with the appropriate safeguards in place. You can request us to erase your personal data at any time."
        />

        <p>
          <TR message="If you are unhappy with our way of handling your personal data, you have the right to lodge a complaint with a supervisory authority" />{' '}
          (
          <L
            url="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
            name={
              <T id="click here for the list of Data Protection Agencies" />
            }
          />
          ).
        </p>
        <p>
          <TR message="We kindly ask you to consider contacting us first, before lodging a complaint, as we can probably find a solution that is faster and better for you." />
        </p>
      </>
    ),
  },
  {
    title: <TR message="Changes to Privacy Policy" />,
    body: (
      <TR
        tagName="p"
        message="We reserve the right to amend this Policy at any time to reflect changes in the law, our data collection and use practices, the features of our Services, or advances in technology. Any changes to the policy will be accessible through the Services and communicated to you by way of an e-mail or a notice on our website"
      />
    ),
  },
];

export const Privacy: React.FC<{}> = () => (
  <Layout>
    <SEO title="Privacy - Hypersay" />
    <ContainerMaxWidth maxWidth="40em" className={styles.container}>
      <h1>
        <TR>Privacy Policy / GDPR Compliance</TR>
      </h1>
      <p>
        <TR message="Latest update: May 18, 2018" />
      </p>
      <p>
        <TR message="This Privacy Policy describes Learn Forward’s practices regarding the collection, use and disclosure of the information we collect from and about you when you use Hypersay’s web-based application and website" />{' '}
        (<Link to="/">hypersay.com</Link> &{' '}
        <L name="hsay.co" url="https://hsay.co" />){' '}
        <TR message="that link to this Privacy Policy. By accessing or using Hypersay, you agree to this Privacy Policy, and our" />{' '}
        <Link to="/terms/">Terms of Service</Link>,{' '}
        <TR message="collectively referred to as: “Terms”." />
      </p>
      <TR
        tagName="p"
        message="IF YOU DO NOT AGREE TO THIS PRIVACY POLICY OR YOU ARE NOT AT LEAST 16 YEARS OLD, YOU MUST NOT USE THE SERVICES."
      />
      <Accordion>
        {sections.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>{item.body}</AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </ContainerMaxWidth>
  </Layout>
);

export default Privacy;
